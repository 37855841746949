
<template>
  <div class="text">
    <div class="content__l">
      <div class="content__l_text">
        <img :src="textInfo.fileId" alt="" />
        <div class="text__title">
          <span>原创</span>
          <div class="time">
            {{ textInfo.createTime }}
          </div>
        </div>
        <div class="text__header">{{ textInfo.newsName }}</div>
        <div class="text__user">
          <el-avatar :size="40" :src="textInfo.authorFile"></el-avatar>
          <div class="username">
            {{ textInfo.author }}
          </div>
        </div>
        <div class="text__detail" v-html="textInfo.detail"></div>
      </div>
      <div class="content__l_like">
        <div class="content_text-bottom">
          <div class="cl" :class="textInfo.isPros == 'pros'?'on':''" @click="handlePros">
            <i class="iconfont icon-heart"></i>
          </div>

          <p>{{ textInfo.newsPros }}人已赞</p>
        </div>
      </div>



      <!-- 相关推荐 -->
      <div class="content__l_recommend">
        <div class="content__l_recommend-header">相关推荐</div>
        <div class="content__l_recommend-item" v-if="bannerList.length != 0">
          <swiper
            ref="mySwiper"
            :options="swiperOptions"
            v-if="bannerList.length != 0"
          >
            <swiper-slide v-for="(item, index) in bannerList" :key="index">
              <div class="item" @click="prevAndNext(item.newsId)">
                <div class="item-top">
                  <img :src="item.fileId" alt="" />
                </div>
                <div class="item-bottom">{{ item.newsName }}</div>
              </div>
            </swiper-slide>
          </swiper>

          <div>
            <el-button
              icon="el-icon-arrow-right"
              circle
              @click="swiperNext"
            ></el-button>
          </div>
        </div>
      </div>




      <div class="content__l_inputText">
        <div class="inputText">
          <div class="inputText_header">
            评论 <span>({{ commentList.length }})</span>
          </div>
          <div class="inputText_input">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4 }"
              :placeholder="placeholder"
              v-model="comment"
              :disabled="userInfo == ''"
              resize="none"
              ref="textareaComm"
            >
            </el-input>
            <div class="inputmsg" v-if="userInfo == ''">
              <p>请<span class="roge" @click="wxlogin">登录</span>后发表评论</p>
            </div>
            <div class="inputbtn">
              <el-button
                type="primary"
                @click="addNewsComment()"
                v-if="userInfo != ''"
              >
                发布评论
              </el-button>
            </div>
          </div>
          <div class="inputText_header">热门评论</div>
          <div class="userText_box">
            <div
              class="userText"
              v-for="(item, index) in commentList"
              :key="index"
            >
              <div class="userimg">
                <el-avatar :src="item.memberIcon" :size="60"></el-avatar>
              </div>
              <div class="username">{{ item.memberName }}</div>
              <div class="usercontent">
                {{ item.comment }}
              </div>
              <div class="userTimer">
                <p>
                  <span>{{ item.time }}</span>
                </p>
                <div class="pros">
                  <span
                    class="ic"
                    @click="isLikes(item.commentPros, 1, item.commentId)"
                  >
                    <i class="iconfont icon-pinglundianzan"></i>
                    {{ item.commentPros }}
                  </span>
                  <span
                    class="ic"
                    @click="isLikes(item.commentCons, 2, item.commentId)"
                  >
                    <i class="iconfont icon-pinglundianzantubiao"></i>
                    {{ item.commentCons }}
                  </span>
                </div>
              </div>
              <div class="userText" v-if="item.child">
                <div class="userimg">
                  <el-avatar :size="60"></el-avatar>
                </div>
                <div class="username">{{ item.memberName }}</div>
                <div class="usercontent">
                  {{ item.comment }}
                </div>
                <div class="userTimer">
                  <p>
                    <span>{{ item.time }}</span>
                  </p>
                  <div class="pros">
                    <span class="ic"
                      ><i class="iconfont icon-pinglundianzan"></i
                      >{{ item.commentPros }}</span
                    >
                    <span class="ic"
                      ><i class="iconfont icon-pinglundianzantubiao"></i
                      >{{ item.commentCons }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="userText_end" v-if="Math.ceil(commentTotal/10)>=page">
              <p>评论已加载完毕～</p>
            </div>
            <div style="text-align: center;">
              <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="commentTotal"
                      :page-size="10"
                      :current-page="page"
                      @current-change="getNewsComment">
              </el-pagination>
            </div>



            <div class="user_menu">
              <span class="box returnTop" @click="returnTop">
                <i class="iconfont icon-fanhuidingbutop"></i>
              </span>
              <span class="box star" @click="shoucang">
                <span class="icon_r">{{ textInfo.collectNum }}</span>
                <i class="iconfont icon-shoucang" :style="textInfo.isCollect == 1?'color: rgb(3, 148, 255)':''"></i>
              </span>
              <span class="box msg msg_box" @click="handleSelect">
                <i class="iconfont icon-pinglun"></i>
              </span>
              <span class="box heart" @click="handlePros">
                <i class="iconfont icon-dianzan" :style="textInfo.isPros == 'pros'?'color: rgb(3, 148, 255)':''"></i>
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="content_r">
      <div class="header">
        <div class="topinfo__r">
          <div class="line"></div>
          <div class="topinfo__r_contanier">
            <div class="topinfo__r_header">
              <span>热门众测</span>
            </div>
            <div class="topinfo__r_content">
              <template v-for="(item, index) in topList">
                <div :key="index" v-if="index< 6">
                  <div class="content-img top1" v-if="index == 0">
                    <div class="box" @click="to('/check/product/info', item.id)">
                      <div class="imgBox">
                        <img :src="item.apparatusFileId" alt="" />
                        <img
                                v-if="!item.apparatusFileId"
                                src="../assets/test.png"
                                alt=""
                        />
                        <div class="content-title top1">
                          <el-button round>点击申请</el-button>
                        </div>
                      </div>

                      <div class="text">
                        <el-avatar :size="44" src=""></el-avatar>
                        <div class="ctext">
                          {{ item.apparatusName }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content-img" v-if="index != 0">
                    <div class="box3" @click="to('/check/product/info', item.id)">
                      <div class="content-title3">
                        {{ item.apparatusName }}
                      </div>
                      <div class="content_time">
                        <i class="iconfont icon-time"></i>{{ item.time }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>

            </div>
            <div class="topinfo__r_content">
              <div class="content-box"></div>
            </div>
            <!-- <div class="topinfo__r_contanier-bottom">
              阅读更多内容，狠戳这里 👉
            </div> -->
          </div>
        </div>
      </div>
      <div class="text__btn" v-if="textInfo">
        <div
          class="text__btn_next"
          v-if="textInfo.next.newsId != 0"
          @click="prevAndNext(textInfo.next.newsId)"
        >
          <img :src="textInfo.next.fileId" alt="" />
          <div class="text__btn_next-box">
            <div class="text__btn_next-top">
              <span>下一篇 <i class="el-icon-caret-right"></i></span>

              <span>{{ textInfo.next.createTime }}</span>
            </div>
            <div class="text__btn_next-bottom">
              <p>
                {{ textInfo.next.newsName }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="text__btn_next"
          v-if="textInfo.prev.newsId != 0"
          @click="prevAndNext(textInfo.prev.newsId)"
        >
          <img :src="textInfo.prev.fileId" alt="" />
          <div class="text__btn_next-box">
            <div class="text__btn_next-top">
              <span>上一篇 <i class="el-icon-caret-left"></i></span>
              <span>{{ textInfo.prev.createTime }}</span>
            </div>
            <div class="text__btn_next-bottom">
              <p>
                {{ textInfo.prev.newsName }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import info from "../api/info";
import api from "../api/api";
export default {
  data() {
    return {
      comment: "",
      textInfo: "",
      commentList: "",
      commentTotal: 0,
      topList: [],
      bannerList: [],
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 20,
        navigation: {
          nextEl: ".prev",
          prevEl: ".next",
        },
        loop: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      },
      placeholder: "",

      page: 1
    };
  },
  components: {
    swiper,
    swiperSlide,
  },

  watch: {
    $route(to, from) {
      this.indexTopAssess();
      this.newsInfo();
      this.getNewsComment(1);
    },
    userInfo(n) {
      this.placeholder = n == "" ? "" : "请输入内容";
    },
  },
  created() {
    this.indexTopNews();
    this.indexTopAssess();
    this.newsInfo();
    this.getNewsComment(1);
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    wxlogin() {
      this.$store.commit("wxLogin", true);
    },
    isLikes(commentCount, islike, id) {
      let data = {
        type: "newsComment",
        operate: islike == 1 ? "pros" : "cons",
        id,
      };

      api.handlePros(data).then((res) => {
        let result = res.data.data;
        this.commentList.forEach((item) => {
          if (item.commentId == result.commentId) {
            item.commentPros = result.commentPros;
            item.commentCons = result.commentCons;
          }
        });
      });
    },
    indexTopNews() {
      api.indexTopNews({type: 'recommend'}).then((res) => {
        this.bannerList = res.data.data.data;
      });
    },
    indexTopAssess() {
      api.indexTopAssess().then((res) => {
        //this.bannerList = res.data.data.data;
        let topList = res.data.data.data;

        topList.forEach((item) => {
          item.time = item.startTime;
        });
        topList = this.Time(topList);

        this.topList = topList;
      });
    },
    prevAndNext(id) {
      this.$router.push(`/info/textinfo/${id}`);
    },
    to(path, id) {
      this.$router.push(`${path}/${id}}`);
    },
    newsInfo() {
      let data = {
        newsId: this.$route.params.id,
      };
      info.newsInfo(data).then((res) => {
        let list = res.data.data;
        list.next.createTime = list.next.createTime
          ? list.next.createTime.split(" ")[1]
          : "";
        list.prev.createTime = list.prev.createTime
          ? list.prev.createTime.split(" ")[1]
          : "";
        list.createTime = list.createTime.split(" ")[0];
        this.textInfo = list;
      });
    },

    getNewsComment(e) {
      this.page = e;
      info.getNewsComment({page: this.page}).then((res) => {
        let data = res.data.data.data;
        data.forEach(item=>{
          item.time = item.createTime
        })
        let list = this.Time(data)
        this.commentList = list;
        this.commentTotal = res.data.data.total
      });
    },
    addNewsComment() {
      let data = {
        newsId: this.$route.params.id,
        comment: this.comment,
      };
      info.addNewsComment(data).then((res) => {
        if(res.data.status == 1) {
          this.comment = ''
          this.getNewsComment();
        }
      });
    },

    shoucang() {
      let data = {
        type: "news",
        id: this.$route.params.id,
      };
      api.collectAdd(data).then(res => {
        if (res.data.status === 1) {
          if(this.textInfo.isCollect === 1) {
            this.$message.warning('已取消');
            this.textInfo.isCollect = 0
            this.textInfo.collectNum = parseInt(this.textInfo.collectNum) - 1
          }else {
            this.$message.success('收藏成功');
            this.textInfo.isCollect = 1
            this.textInfo.collectNum = parseInt(this.textInfo.collectNum) + 1
          }
        }else {
          this.$message.warning(res.data.msg)
        }
      });
    },
    handlePros() {
      let data = {
        type: 'news',
        operate: 'pros',
        id: this.$route.params.id
      };
      api.handlePros(data).then(res => {
        if (res.data.status === 1) {
          if(this.textInfo.isPros == 'pros') {
            this.textInfo.isPros = ''
          }else {
            this.textInfo.isPros = 'pros'
          }
          this.textInfo.newsPros = res.data.data.newsPros || 0
        }else {
          this.$message.warning(res.data.msg)
        }
      });
    },
    handleSelect() {
      this.$refs.textareaComm.select()
    },
    returnTop() {
      document
        .querySelector(".content__l_text")
        .scrollIntoView({ behavior: "smooth" });
    },
    swiperNext() {
      this.swiper.slideNext();
    },
  },
};
</script>
<style lang="scss">
.text {
  .content-title {
    &.top1 {
      .el-button {
        background: scroll;
        color: #fff;
      }
    }
  }
}
.inputText_input {
  div {
    text-align: right;
  }
  .el-button {
  }
  .el-textarea {
    .el-textarea__inner {
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #888888;
      background: #f2f2f2;
      outline: none;
    }
  }
}
.inputbtn {
  position: absolute;

  right: 13px;
  bottom: 13px;
  .el-button {
    margin: 0;
    background: #e6e6e6;
    font-size: 19px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding: 9px 13px;
    border: none;
    border-radius: 3px 3px 3px 3px;
  }
}
</style>
<style lang="scss" scoped>
.text {
  display: flex;
  justify-content: space-between;

  .text__title {
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #b6b6b6;
    line-height: 19px;
    margin-top: 30px;
    margin-bottom: 5px;
    .time {
      margin-left: 9px;
      display: inline-block;
      font-size: 21px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #b6b6b6;
    }
    span {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #d28d4e;
      background: #f0f0f0;
      display: inline-block;
      border-radius: 32px;
      padding: 5px 7px;
    }
  }
  .text__header {
    font-size: 43px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-bottom: 23px;
  }
  .text__detail {
    img {
      width: 100%;
    }
  }
  .text__user {
    display: flex;
    align-items: center;
    font-size: 19px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-bottom: 33px;
    .username {
      margin-left: 9px;
      font-size: 19px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .text__btn {
    .text__btn_next {
      margin-top: 13px;
      // height: 160px;
      // padding: 13px;
      border-radius: 7px;
      // background: url("../assets/test.png") no-repeat;
      background-size: 100% auto;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      position: relative;
      img {
        border-radius: 7px;
      }
      .text__btn_next-box {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      .text__btn_next-top {
        display: flex;
        justify-content: space-between;
        padding: 13px;
        align-items: center;
        span {
          &:nth-child(1) {
            font-size: 19px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
          &:nth-child(2) {
            font-size: 16px;
            font-family: Helvetica-Regular, Helvetica;
            font-weight: 400;
            color: #c9c9c9;
          }
        }
      }
      .text__btn_next-bottom {
        padding: 13px;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  .content__l {
    width: 859px;

    .content__l_text {
      border-radius: 7px;
      border: 1px solid #e9e9e9;
      padding: 13px 25px;
      font-size: 21px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      img {
        width: 100%;
      }
      p {
        margin: 25px 0;
      }
    }
    .content__l_like {
      .content_text-bottom {
        padding: 50px;

        // border-bottom: 1px solid #f0f0f0;
        .cl {
          cursor: pointer;
          margin: 0 auto;
          width: 80px;
          height: 80px;
          line-height: 80px;
          text-align: center;
          background: #ced3db;
          border-radius: 50%;
          box-shadow: 0px 5px 21px 1px
            rgba(
              167.00000524520874,
              201.99998795986176,
              255,
              0.5699999928474426
            );
          /*&:hover {*/
          /*  background: #f8a37f;*/
          /*  box-shadow: 0px 5px 21px 1px rgba(248, 163, 127, 0.5699999928474426);*/
          /*}*/
          &.on {
            background: #f8a37f;
            box-shadow: 0px 5px 21px 1px rgba(248, 163, 127, 0.5699999928474426);
          }
          i {
            font-size: 32px;
            color: #fff;
          }
        }
        p {
          text-align: center;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 19px;
          margin-top: 17px;
        }
      }
    }
    .content__l_recommend {
      padding: 40px 0;
      border-bottom: 1px solid #f0f0f0;
      .content__l_recommend-header {
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        margin: 30px 0;
      }
      .content__l_recommend-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item {
          width: 213px;
          border-radius: 7px;
          cursor: pointer;
          .item-top {
            font-size: 0;
            img {
              width: 100%;
              border-radius: 7px;
            }
          }
          .item-bottom {
            background: #f6f6f6;
            padding: 15px 21px;
          }
        }
      }
    }
    .content__l_inputText {
      .inputText_input {
        position: relative;
        .inputmsg {
          position: absolute;
          left: 33px;
          top: 50%;
          transform: translateY(-50%);
          color: #888888;
          font-size: 24px;
          font-family: PingFang SC-Heavy;
          font-weight: 400;

          span {
            cursor: pointer;
            color: #0394ff;
            font-family: PingFang SC-Regular;
            font-size: 24px;
            font-weight: bold;
          }
        }
      }
      .inputText {
        margin-top: 53px;
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;

        .inputText_header {
          margin: 50px 0;
          color: #333;
          span {
            color: #999999;
          }
        }
        .userText_box {
          position: relative;
        }
        .userText {
          display: grid;
          grid-template-columns: 80px auto;
          grid-template-rows: 60px auto auto auto;
          font-size: 21px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 60px;
          .userimg {
            grid-row-start: 1;
            grid-row-end: 5;
          }
          .userTimer {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #bac0cf;
            .pros {
              width: 135px;
              display: flex;
              justify-content: space-between;
              .ic {
                cursor: pointer;
                i {
                  font-size: 15px;
                  color: #bac0cf;
                }
              }
            }
          }
        }
        .userText_end {
          padding: 63px 0;
          font-size: 19px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          text-align: center;
        }
        .user_menu {
          display: flex;
          flex-direction: column;
          position: absolute;
          right: -140px;
          top: -152px;
          cursor: pointer;
          .box {
            display: block;
            width: 61px;
            height: 61px;
            border-radius: 50%;
            // border: 1px solid #cee0ea;
            position: relative;
            margin-top: 24px;
            &.msg_box {
              &:hover {
                i{
                  color: rgb(3, 148, 255)
                }
              }
            }
            i {
              font-size: 61px;
              color: #cee0ea;
            }
            .icon_r {
              position: absolute;
              right: 0;
              display: block;
              width: 27px;
              height: 27px;
              line-height: 27px;
              text-align: center;
              background: #ff2e2e;
              font-size: 16px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #fbfbfb;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .content_r {
    width: 296px;
    .header {
      .topinfo__r {
        // width: 328px;
        border-radius: 7px;
        margin-top: 1px;
        // border-top: 5px solid #0394FF;
        box-shadow: 0px 4px 21px 1px
          rgba(
            94.0000019967556,
            94.0000019967556,
            94.0000019967556,
            0.23999999463558197
          );

        .line {
          height: 5px;
          background: #fbfbfb;
          border-radius: 7px 7px 0 0;
        }
        .topinfo__r_contanier {
          // border: 1px solid #e9e9e9;
          .topinfo__r_header {
            padding: 10px 19px;
            span {
              padding: 5px 0;
              border-bottom: 3px solid #0092ff;
              font-size: 16px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #0394ff;
            }
          }
        }

        .topinfo__r_content {
          .content-img {
            padding: 13px 16px;
            &.top1 {
              padding: 25px 16px 0 16px;
              .box {
                border-radius: 7px;
                background: #ffffff;
                .text {
                  padding: 13px 21px;
                  align-items: center;
                  font-size: 16px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;

                  justify-content: flex-start;
                  .ctext {
                    margin-left: 13px;
                  }
                }
                .imgBox {
                  position: relative;
                  font-size: 0;
                }
                &:hover {
                  box-shadow: 0px 1px 24px 1px
                    rgba(0, 0, 0, 0.07000000029802322);
                }
              }
            }
            .box {
              position: relative;
              img {
                width: 100%;
                border-radius: 7px 7px 0 0;
              }
            }
            .box2 {
              display: grid;
              grid-template-columns: 50% 50%;
              grid-template-rows: auto auto;
              grid-column-gap: 15px;
              grid-row-gap: 15px;
              img {
                width: 100%;
                border-radius: 7px;
                grid-row-start: 1;
                grid-row-end: 3;
              }
              .content-title2 {
                font-size: 17px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
              }
              .content-time {
                display: flex;
                align-items: flex-end;
                font-size: 16px;
                font-family: Helvetica-Regular, Helvetica;
                font-weight: 400;
                color: #b1b1b1;
              }
            }
            .box3 {
              .content-title3 {
                padding: 7px 0;
                font-size: 19px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #666666;
              }
              .content_time {
                font-size: 16px;
                font-family: Roboto-Regular, Roboto;
                font-weight: 400;
                color: #b1b1b1;
                // padding: 20px 0;
                padding-bottom: 13px;
                border-bottom: 1px solid #d2d4d6;
                i {
                  margin-right: 11px;
                }
              }
              &:hover {
                .content-title3 {
                  color: #0394ff;
                }
              }
            }
          }

          .content-title {
            &.top1 {
              //   width: 217px;
              position: absolute;
              padding: 13px 21px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              //   border: 1px solid #ffffff;
              font-size: 19px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
          }
        }
        .topinfo__r_contanier-bottom {
          padding: 9px 0;
          text-align: center;
          background: #0394ff;
          color: #fff;
          border-radius: 0 0 7px 7px;
        }
      }
    }
  }
}
</style>
